import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid'
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SignupForm from '../components/SignupForm';

class SignupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
    }

    render() {
        return (
            <>
                <Layout>
                    <Seo title="Home" />
                    <div>
                        <div className="container mx-auto mt-8 p-8 md:p-0">
                            <div className="md:grid md:grid-cols-3 md:gap-4">
                                <div className="bg-yellow-400 rounded-lg p-8 x">
                                    <h1 className="font-serif text-4xl font-bold ">You've been invited to FindingCreators.</h1>
                                    <p className="pt-8 text-lg">
                                        Hey there!
            <br /><br />
            You have been invited to Finding Creators! Finding Creators is a platform where we bring creators together with their fans.
            <br /> <br />
            We are not recreating OnlyFans or any other website like that. We are building a discovery engine for fans to easily find creators like you. Got a feet fetish? We'll list all the
            profiles of creators with a feetfetish for the user who is looking for a creator who makes foot fetish content.
            <br /><br />
            We would love to list your page on the website. We won't charge you any money for it as we are starting out. <span className="font-bold">Pleae note that we will manually review all signups.</span> 
            <br /><br />
            Once you are approved, we will send you a link where you can enter your listing details
            <br /> <br />
                                        <span className="text-pink-700 hover:font-bold cursor-pointer	">For questions you can email secure [at] nvtp.nl</span>
                                    </p>
                                </div>
                                <div className="bg-yellow-400 rounded-lg p-8 col-span-2 md:w-2/3 mt-4 md:mt-0">
                                    <SignupForm />   
                                


</div>


                            </div>

                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default SignupPage