import React, { useState } from "react"
import axios from "axios";
import { Link } from "gatsby"
import Layout from "../components/layout"
const SignupForm = () => {

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/c7e22a9c-63f7-4864-82fe-1477a15cde8f",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Thanks!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };
    return (
        <Layout>

            <div>
                <div className="col-md-8 mt-5">
                    <form onSubmit={handleOnSubmit}>
                        <div>
                            <label htmlFor="name" className="block font-bold font-serif text-gray-700">
                                Name
      </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 h-12 p-4 rounded-md"
                                    placeholder="Kayleigh"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="email" className="block font-bold font-serif text-gray-700">
                                Email
      </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 h-12 p-4 rounded-md"
                                    placeholder="kayleigh@live.co.uk"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="profile_url" className="block font-bold font-serif text-gray-700">
                                Creator profile
      </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="profile_url"
                                    id="profile_url"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 h-12 p-4 rounded-md"
                                    placeholder="onlyfans.com/username"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                        <button
        type="submit"
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm bg-white text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >Submit</button></div>

                    </form>
                </div>
            </div>

        </Layout>

    );
};

export default SignupForm;
